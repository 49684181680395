import React from "react";
import SEO from "../components/common/seo.js";
import Layout from "../components/common/layout";
import Navigation from "../components/navigation/navigation";
import Header from "../components/sections/header";
import styled from "styled-components";
const ErrorPage = () => {
  return (
    <Layout>
      <SEO
        title='Kariera'
        description='MWDK / Waś, Krajewski & Wspólnicy z siedzibą w Warszawie przy ulicy
          Nowy Świat 6/12 podejmie współpracę ze studentami, magistrami prawa,
          aplikantami lub radcami prawnymi / adwokatami.'
      />
      <Navigation />
      <Header />
      <NotFound>Strona o wybranym adresie nie istnieje</NotFound>
    </Layout>
  );
};

export default ErrorPage;

const NotFound = styled.h4`
  color: black;
  font-size: 15px;
  padding: 150px 0;
`;
